<template>
    <v-card>
        <v-card-title>
            <h6 class="text-uppercase font-weight-bold text-subtitle-1">Recursos de revisión presentados</h6>
        </v-card-title>
        <v-divider />
        <v-card-text>
            <div v-if="recursos.isLoading" class="d-flex align-center" style="gap: 8px;">
                <v-progress-circular indeterminate :size="20" :width="2" />
                <span class="font-weight-bold">Cargando recursos de revisión...</span>
            </div>
            <v-expansion-panels accordion>
                <v-expansion-panel v-for="(recurso) in recursos.data" :key="recurso.id">
                  <v-expansion-panel-header>
                    <div class="d-flex justify-space-between align-center flex-wrap pr-2 pr-md-4" style="gap: 16px;">
                        <div class="d-flex align-center flex-wrap" style="gap: 4px;">
                            <v-icon style="transform: translateY(-2px)">mdi-account</v-icon>
                            <span class="font-weight-medium text-subtitle-1" style="word-break: word-break;">
                                {{ recurso.Usuario.Proveedor.nombre_comercial }}
                            </span>
                        </div>
                        <v-chip :color="getColor(recurso.EstadoImpugnacion?.id)" style="display: block" class="white--text" label>
                            {{ recurso?.EstadoImpugnacion?.nombre }}
                        </v-chip>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <v-divider />
                      <div class="px-4 py-4">
                        <dl>
                            <dt class="font-weight-bold text-body-2">Proveedor:</dt>
                            <dd>{{ recurso.Usuario.Proveedor.nombre_comercial }}</dd>

                            <dt class="font-weight-bold mt-4 text-body-2">Correlativo:</dt>
                            <dd>{{ recurso.correlativo }}</dd>

                            <dt class="font-weight-bold mt-4 text-body-2">Fecha de presentación:</dt>
                            <dd>{{ recurso?.created_at ? formatDate(recurso.created_at): null }}</dd>

                            <template v-if="recurso.acta">
                                <dt class="font-weight-bold mt-4 text-body-2">Fecha de {{ recurso.EstadoImpugnacion.id === 2 ? 'admisión' : 'rechazo' }}:</dt>
                                <dd>{{ formatDate(recurso.acta.created_at) }}</dd>
                            </template>
                        </dl>
                        

                        <div class="mt-8 d-flex align-center flex-wrap" style="gap: 16px;">
                            <v-btn
                                color="primary"
                                class="flex-grow-1 flex-sm-grow-0 py-2"
                                style="height: unset !important; max-width: 100%;"
                                @click.stop="visualizarRecursoRevision(recurso)"
                            >
                                <v-icon color="white" class="mr-2">mdi-eye</v-icon>
                                <span style="word-break: break-word; white-space: normal;">Ver recurso</span>
                            </v-btn>

                            <v-btn 
                                v-if="recurso.acta"
                                color="secondary"
                                class="flex-grow-1 flex-sm-grow-0 py-2"
                                style="height: unset !important; max-width: 100%;"
                                @click.stop="visualizarActa(recurso)"
                            >
                                <v-icon color="white" class="mr-2">mdi-eye</v-icon>
                                <span style="word-break: break-word; white-space: normal;">Ver acta de {{ recurso.EstadoImpugnacion.id === 2 ? 'admisión' : 'rechazo' }}</span>
                            </v-btn>

                            <v-btn
                                v-if="recurso.prevencion"
                                color="secondary"
                                class="flex-grow-1 flex-sm-grow-0 py-2"
                                style="height: unset !important; max-width: 100%;"
                                @click.stop="visualizarPrevencion(recurso)"
                            >
                                <v-icon color="white" class="mr-2">mdi-folder-eye</v-icon>
                                <span style="word-break: break-word; white-space: normal;">Ver prevención</span>
                            </v-btn>


                            <v-btn 
                                v-if="recurso.prevencion"
                                color="secondary"
                                class="flex-grow-1 flex-sm-grow-0 py-2"
                                style="height: unset !important; max-width: 100%;"
                                @click.stop="visualizarDetallePrevencion(recurso)"
                            >
                                <v-icon color="white" class="mr-2">mdi-folder-text</v-icon>
                                <span style="word-break: break-word; white-space: normal;">Detalles de prevención</span>
                            </v-btn>

                            <v-btn 
                                v-if="recurso.prevencion?.subsanacion"
                                color="secondary"
                                class="flex-grow-1 flex-sm-grow-0 py-2"
                                style="height: unset !important; max-width: 100%;"
                                @click.stop="visualizarSubsanacion(recurso)"
                            >
                                <v-icon color="white" class="mr-2">mdi-folder-wrench</v-icon>
                                <span style="word-break: break-word; white-space: normal;">Ver subsanación</span>
                            </v-btn>
                        </div>
                      </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>

        <PdfModal
            :isOpen.sync="previsualizacionRecursoRevisionAbierta" 
            :source-loadable="visualizacionRecursoRevision"
            filename="recurso-revision"
        />

        <PdfModal
            :isOpen.sync="previsualizacionPrevencionAbierta" 
            :source-loadable="visualizacionPrevencion"
            filename="documento-prevencion"
        />

        <ModalDetallePrevencion 
            :is-open.sync="modalDetallePrevencionAbierta"
            :id-recurso-revision="recursoRevisionActivo?.id"
            :service="serviceDetallePrevencion"
        />

        <PdfModal
            :isOpen.sync="previsualizacionSubsanacionAbierta" 
            :source-loadable="visualizacionSubsanacion"
            filename="documento-subsanacion"
        />

        <PdfModal
            :isOpen.sync="previsualizacionActaAbierta" 
            :source-loadable="visualizacionActa"
            filename="acta-recurso"
        />
    </v-card>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import ModalDetallePrevencion from '@/views/ProcesoCompraDoc/RecursosRevision/components/prevencionRecursoRevision/ModalDetallePrevencion.vue';

export default {
    name: 'RecursosPresentados',
    components: { DataTableComponent, PdfModal, ModalDetallePrevencion },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data: () => ({
        // UI
        previsualizacionPrevencionAbierta: false,
        previsualizacionRecursoRevisionAbierta: false,
        previsualizacionSubsanacionAbierta: false,
        previsualizacionActaAbierta: false,
        modalDetallePrevencionAbierta: false,
        recursoRevisionActivo: null,

        // DATA
        recursos: createLoadable([]),
        visualizacionPrevencion: createLoadable(null),
        visualizacionRecursoRevision: createLoadable(null),
        visualizacionSubsanacion: createLoadable(null),
        visualizacionActa: createLoadable(null),
    }),
    computed: {
        headers() {
            return  [
                { text: "Código", align: "center", value: "correlativo", align: "center", sortable: false },
                { text: "Proveedor", align: "center", value: "Usuario.Proveedor.nombre_comercial", align: "center", sortable: false },
                { text: "Fecha de creación", value: "created_at", align: "center", sortable: false },
                { text: "Estado", value: "estado", align: "center", sortable: false },
                { text: "Acciones", align: "center", value: "acciones", sortable: false },
            ];
        },
        serviceDetallePrevencion() {
            return this.services.HerramientaRecursoRevision.obtenerDetallesPrevencionRecursoRevision;
        },
    },
    methods: {
        // UI
        formatDate,
        getColor(id_estado) {
            switch (id_estado) {
                case 1:
                return "#8EBCE1";
                case 2:
                return "green";
                case 3:
                return "red";
                default:
                return "grey";
            }
        },
        // DATA
        async cargarRecursosPresentados() {
            toggleLoadable(this.recursos);
            const { data } = await this.services.HerramientaRecursoRevision.cargarRecursos(this.idProcesoCompra);
            setLoadableResponse(this.recursos, data);
        },
        async visualizarPrevencion(recursoRevision) {
            this.previsualizacionPrevencionAbierta = true;
            toggleLoadable(this.visualizacionPrevencion);
            const { data } = await this.services.HerramientaRecursoRevision.visualizarPrevencion(recursoRevision.prevencion.id);
            setLoadableResponse(this.visualizacionPrevencion, data, { isFile: true });
        },
        async visualizarRecursoRevision(recursoRevision) {
            this.previsualizacionRecursoRevisionAbierta = true;
            toggleLoadable(this.visualizacionRecursoRevision);
            const { data } = await this.services.HerramientaRecursoRevision.visualizarRecursoRevision(recursoRevision.id);
            setLoadableResponse(this.visualizacionRecursoRevision, data, { isFile: true });
        },
        visualizarDetallePrevencion(recursoRevision) {
            this.recursoRevisionActivo = { ...recursoRevision };
            this.modalDetallePrevencionAbierta = true;
        },
        async visualizarSubsanacion(recursoRevision) {
            this.previsualizacionSubsanacionAbierta = true;
            toggleLoadable(this.visualizacionSubsanacion);
            const { data } = await this.services.HerramientaRecursoRevision.visualizarSubsanacion(recursoRevision.prevencion.subsanacion.id);
            setLoadableResponse(this.visualizacionSubsanacion, data, { isFile: true });
        },
        async visualizarActa(recursoRevision) {
            this.previsualizacionActaAbierta = true;
            toggleLoadable(this.visualizacionActa);
            const { data } = await this.services.HerramientaRecursoRevision.visualizarActaRecurso(recursoRevision.acta.id);
            setLoadableResponse(this.visualizacionActa, data, { isFile: true });
        },
    },
    created() {
        this.cargarRecursosPresentados();
    },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content .v-expansion-panel-content__wrap) {
    padding: 0 !important;
}

:deep(.v-btn__content) {
    max-width: 100%;
    flex-wrap: wrap;
}
</style>
