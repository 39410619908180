import { render, staticRenderFns } from "./modalComplementoEtapa.vue?vue&type=template&id=f13e1880"
import script from "./modalComplementoEtapa.vue?vue&type=script&lang=js"
export * from "./modalComplementoEtapa.vue?vue&type=script&lang=js"
import style0 from "./modalComplementoEtapa.vue?vue&type=style&index=0&id=f13e1880&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports